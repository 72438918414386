<template>
  <div class="problem">
    <NavBar :title="navBarList[type - 1]" routeUrl="/" />
    <div class="problem-body">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="top"
        class="demo-ruleForm login-container"
      >
        <!-- <h3 class="title">工单提交</h3> -->
        <el-form-item
          :label="$t('problem.rechargeMethod')"
          prop="recharge_mode"
          key="recharge_mode"
          v-if="type === 2"
        >
          <el-select
            @change="changeRechargeMode"
            v-model="ruleForm.recharge_mode"
            :placeholder="$t('problem.rechargeMethodPlaceholder')"
          >
            <el-option
              :label="$t('problem.applePurchase')"
              value="1"
            ></el-option>
            <el-option
              :label="$t('problem.googlePurchase')"
              value="2"
            ></el-option>
            <el-option :label="$t('problem.WebPurchase')" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('problem.rechargeType')"
          prop="recharge_type"
          key="recharge_type"
          v-if="type === 2"
        >
          <el-select
            @change="changeRechargeType"
            v-model="ruleForm.recharge_type"
            :placeholder="$t('problem.rechargeTypePlaceholder')"
          >
            <el-option
              :label="$t('problem.abnormalRecharge')"
              value="1"
            ></el-option>
            <el-option
              :label="$t('problem.itemsNotReceived')"
              value="2"
            ></el-option>
            <el-option
              :label="$t('problem.cardRecharge')"
              value="3"
              v-if="ruleForm.recharge_mode == 3"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('problem.cardNum')"
          prop="cardSerial"
          key="cardSerial"
          v-if="
            type === 2 &&
            ruleForm.recharge_mode == 3 &&
            ruleForm.recharge_type == 3
          "
        >
          <el-input
            v-model="ruleForm.cardSerial"
            :placeholder="$t('problem.cardNumPlaceholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.paySuccessTime')"
          prop="pay_time"
          key="pay_time"
          v-if="type === 2 && ruleForm.recharge_type === '2'"
        >
          <el-date-picker
            v-model="ruleForm.pay_time"
            type="datetime"
            :placeholder="$t('problem.paySuccessTimePlaceholder')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('problem.payAmount')"
          prop="pay_amt"
          key="pay_amt"
          v-if="type === 2 && ruleForm.recharge_type === '2'"
        >
          <el-input
            v-model="ruleForm.pay_amt"
            :placeholder="$t('problem.payAmountPlaceholder')"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.LoginType')"
          prop="login_type"
          key="login_type"
          v-if="type === 6"
        >
          <el-select
            @change="handleLoginType"
            v-model="ruleForm.login_type"
            :placeholder="$t('problem.LoginTypePlaceholder')"
          >
            <el-option
              :label="$t('problem.AccountProblem')"
              value="1"
            ></el-option>
            <el-option
              :label="$t('problem.selectServerProblem')"
              value="2"
            ></el-option>
            <el-option
              :label="$t('problem.roleLoginProblem')"
              value="3"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('problem.loginMethod')"
          prop="login_mode"
          key="login_mode"
          v-if="ruleForm.login_type == 1"
        >
          <el-select
            @change="handleLoginMode"
            v-model="ruleForm.login_mode"
            :placeholder="$t('problem.loginMethodPlaceholder')"
          >
            <el-option :label="$t('problem.emailLogin')" value="1"></el-option>
            <el-option :label="$t('problem.FbLogin')" value="2"></el-option>
            <el-option :label="$t('problem.googleLogin')" value="3"></el-option>
            <el-option :label="$t('problem.guestLogin')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Email"
          prop="email"
          key="email"
          v-if="ruleForm.login_type == 1 && ruleForm.login_mode == 1"
        >
          <el-input
            v-model="ruleForm.email"
            :placeholder="$t('problem.emailPlaceholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.chooseGame')"
          :prop="type === 8 ? '' : 'game_id'"
          key="game_id"
        >
          <el-select
            @change="handleGameChange"
            v-model="ruleForm.game_id"
            :placeholder="$t('problem.chooseGameplaceholder')"
          >
            <el-option
              :label="item.game_name"
              :value="item.game_id"
              v-for="item in servers_roles.game"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('problem.chooseServer')"
          :prop="[3, 8].includes(type) ? '' : 'server_id'"
          key="server_id"
          v-if="ruleForm.recharge_type !== '3' && ruleForm.login_type !== '1'"
        >
          <el-select
            @change="handleServerChange"
            v-model="ruleForm.server_id"
            :placeholder="$t('problem.chooseServerplaceholder')"
          >
            <el-option
              :label="item.server_name"
              :value="item.server_id"
              v-for="item in servers_roles.server"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('problem.chooseRole')"
          :prop="[3, 8].includes(type) ? '' : 'role_id'"
          key="role_id"
          v-if="
            ruleForm.recharge_type !== '3' &&
            ruleForm.login_type !== '1' &&
            ruleForm.login_type !== '2'
          "
        >
          <el-select
            v-model="ruleForm.role_id"
            :placeholder="$t('problem.chooseRoleplaceholder')"
          >
            <el-option
              :label="item.role_name"
              :value="item.role_id"
              v-for="item in servers_roles.roles"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('problem.fbLink')"
          prop="fb_link"
          key="fb_link"
          v-if="type === 3"
        >
          <el-input
            v-model="ruleForm.fb_link"
            :placeholder="$t('problem.fbLinkPlaceholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.bugOccur')"
          prop="occur_time"
          key="occur_time"
          v-if="type === 4 || type == 6"
        >
          <el-date-picker
            v-model="ruleForm.occur_time"
            type="datetime"
            :placeholder="$t('problem.bugOccurPlaceholder')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('problem.gameVersion')"
          prop="bug_game_version"
          key="bug_game_version"
          v-if="
            (ruleForm.recharge_mode != 3 && ruleForm.recharge_type == 1) ||
            type === 4 ||
            type === 6
          "
        >
          <el-input
            v-model="ruleForm.bug_game_version"
            :placeholder="$t('problem.gameVersionExample')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.phoneSystemVersion')"
          prop="mobile_system_version"
          key="mobile_system_version"
          v-if="
            (ruleForm.recharge_mode != 3 && ruleForm.recharge_type == 1) ||
            type === 4 ||
            type === 6
          "
        >
          <el-input
            v-model="ruleForm.mobile_system_version"
            :placeholder="$t('problem.phoneSystemVersionExample')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.mobileDevice')"
          prop="mobile_device"
          key="mobile_device"
          v-if="
            (ruleForm.recharge_mode != 3 && ruleForm.recharge_type == 1) ||
            type === 4 ||
            type === 6
          "
        >
          <el-input
            v-model="ruleForm.mobile_device"
            :placeholder="$t('problem.mobileDeviceExample')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.deviceInfo')"
          prop="device_info"
          key="device_info"
          v-if="type === 4 || type === 6"
        >
          <el-input
            v-model="ruleForm.device_info"
            :placeholder="$t('problem.deviceInfoExample')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.network')"
          prop="network"
          key="network"
          v-if="type === 6"
        >
          <el-input
            v-model="ruleForm.network"
            :placeholder="$t('problem.networkExample')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.appealType')"
          prop="appeal_type"
          key="appeal_type"
          v-if="type === 5"
        >
          <el-select
            v-model="ruleForm.appeal_type"
            :placeholder="$t('problem.appealTypePlaceholder')"
          >
            <el-option :label="$t('problem.blockIssue')" value="1"></el-option>
            <el-option :label="$t('problem.muteIssue')" value="2"></el-option>
            <el-option :label="$t('problem.otherIssues')" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('problem.complaintsAndReports')"
          prop="report_type"
          key="report_type"
          v-if="type === 7"
        >
          <el-select
            v-model="ruleForm.report_type"
            :placeholder="$t('problem.complaintsAndReportsPlaceholder')"
          >
            <el-option
              :label="$t('problem.IllegalSpeech')"
              value="1"
            ></el-option>
            <el-option
              :label="$t('problem.IllegalCharacterName')"
              value="2"
            ></el-option>
            <el-option
              :label="$t('problem.ReportCheating')"
              value="3"
            ></el-option>
            <el-option
              :label="$t('problem.OtherViolations')"
              value="4"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('problem.characterName')"
          prop="report_role_name"
          key="report_role_name"
          v-if="type === 7"
        >
          <el-input
            v-model="ruleForm.report_role_name"
            :placeholder="$t('problem.characterNamePlaceholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.serverName')"
          prop="report_server_name"
          key="report_server_name"
          v-if="type === 7"
        >
          <el-input
            v-model="ruleForm.report_server_name"
            :placeholder="$t('problem.serverNamePlaceholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.characterID')"
          prop="report_role_id"
          key="report_role_id"
          v-if="type === 7"
        >
          <el-input
            v-model="ruleForm.report_role_id"
            :placeholder="$t('problem.characterIDPlaceholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.uploadImg')"
          prop="imgUrl"
          key="imgUrl"
        >
          <el-upload
            class="upload-demo"
            action="https://ticket.muugame.com/api/upload"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="3"
            :before-upload="handlebeforeUpload"
            :on-exceed="handleExceed"
            :on-success="handleSuccess"
            accept="image/jpeg, image/png"
            :file-list="fileImgList"
          >
            <i class="el-icon-plus"></i>
            <!-- <el-button size="small" type="primary">
              {{ $t("problem.confirmUpload") }}
            </el-button> -->
            <div slot="tip" class="el-upload__tip">
              {{ $t("problem.confirmUploadDec") }}
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          :label="$t('problem.uploadVideo')"
          prop="videoUrl"
          key="videoUrl"
          v-if="ruleForm.recharge_type != 2"
        >
          <el-upload
            class="upload-demo"
            action="https://ticket.muugame.com/api/upload"
            :before-upload="beforeUpload"
            :on-exceed="onExceed"
            :on-success="onSuccess"
            :on-error="onError"
            :on-preview="handleVideoPreview"
            :on-remove="handleRemove"
            accept="video/*"
            :limit="1"
            :file-list="fileVideoList"
            list-type="picture-card"
          >
            <!-- <el-button slot="trigger" size="small" type="primary">
              {{ $t("problem.confirmUpload") }}
            </el-button> -->
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              <div>{{ $t("problem.confirmUploadVideoDec") }}</div>
              <div style="line-height: 15px; color: red">
                {{ $t("problem.confirmUploadVideoDec2") }}
              </div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          :label="$t('problem.decProblem')"
          prop="description"
          key="description"
        >
          <el-input type="textarea" v-model="ruleForm.description"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('problem.contact')"
          prop="contact"
          key="contact"
        >
          <el-input
            v-model="ruleForm.contact"
            :placeholder="$t('problem.contactPlaceholder')"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%"
            @click.native.prevent="handleSubmit"
            :loading="logining"
            >{{ $t("problem.confirm") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      :width="$isMobile ? '90%' : '500px'"
      center
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <img width="50%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog
      :width="$isMobile ? '90%' : '500px'"
      center
      :visible.sync="DialogVideoVisible"
      :before-close="handleClose"
    >
      <!-- <img width="100%" :src="dialogImageUrl" alt="" /> -->
      <video :src="videoUrl" controls width="320" height="240"></video>
    </el-dialog>
    <Kefu />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Kefu from "@/components/kefu";
export default {
  name: "",
  mixins: [],
  components: { NavBar, Kefu },
  props: {},
  data() {
    return {
      type: 1,
      logining: false,
      token: localStorage.getItem("token"),
      ruleForm: {
        game_id: "",
        server_id: "",
        role_id: "",
        recharge_type: "",
        recharge_mode: "",
        cardSerial: "",
        bug_game_version: "",
        mobile_system_version: "",
        mobile_device: "",
        device_info: "",
        network: "",
        login_type: "",
        login_mode: "",
        email: "",
        pay_time: "",
        pay_amt: "",
        fb_link: "",
        occur_time: "",
        appeal_type: "",
        report_type: "",
        report_role_name: "",
        report_server_name: "",
        report_role_id: "",
        description: "",
        contact: "",
        imgUrl: [],
        videoUrl: "",
      },
      servers_roles: {
        game: [],
        server: [],
        roles: [],
      }, //区服角色
      fileList: [], //图片资源
      fileImgList: [],
      videoUrl: "",
      fileVideoList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      DialogVideoVisible: false,
    };
  },
  computed: {
    navBarList() {
      return [
        this.$t("Main.problem1"),
        this.$t("Main.problem2"),
        this.$t("Main.problem3"),
        this.$t("Main.problem4"),
        this.$t("Main.problem5"),
        this.$t("Main.problem6"),
        this.$t("Main.problem7"),
        this.$t("Main.problem8"),
      ];
    },
    rules() {
      return {
        game_id: [
          {
            required: true,
            message: this.$t("problem.chooseGameplaceholder"),
            trigger: "blur",
          },
        ],
        server_id: [
          {
            required: true,
            message: this.$t("problem.chooseServerplaceholder"),
            trigger: "blur",
          },
        ],
        role_id: [
          {
            required: true,
            message: this.$t("problem.chooseRoleplaceholder"),
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("problem.problemStyleplaceholder"),
            trigger: "blur",
          },
        ],
        recharge_type: [
          {
            required: true,
            message: this.$t("problem.rechargeTypePlaceholder"),
            trigger: "blur",
          },
        ],
        recharge_mode: [
          {
            required: true,
            message: this.$t("problem.rechargeMethodPlaceholder"),
            trigger: "blur",
          },
        ],
        cardSerial: [
          {
            required: true,
            message: this.$t("problem.cardNumPlaceholder"),
            trigger: "blur",
          },
        ],
        bug_game_version: [
          {
            required: true,
            message: this.$t("problem.gameVersionExample"),
            trigger: "blur",
          },
        ],
        mobile_system_version: [
          {
            required: true,
            message: this.$t("problem.phoneSystemVersionExample"),
            trigger: "blur",
          },
        ],
        mobile_device: [
          {
            required: true,
            message: this.$t("problem.mobileDeviceExample"),
            trigger: "blur",
          },
        ],
        device_info: [
          {
            required: true,
            message: this.$t("problem.deviceInfoExample"),
            trigger: "blur",
          },
        ],
        network: [
          {
            required: true,
            message: this.$t("problem.networkExample"),
            trigger: "blur",
          },
        ],
        login_type: [
          {
            required: true,
            message: this.$t("problem.LoginTypePlaceholder"),
            trigger: "blur",
          },
        ],
        login_mode: [
          {
            required: true,
            message: this.$t("problem.loginMethodPlaceholder"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("problem.emailPlaceholder"),
            trigger: "blur",
          },
        ],
        pay_time: [
          {
            type: "date",
            required: true,
            message: this.$t("problem.paySuccessTimePlaceholder"),
            trigger: "change",
          },
        ],
        pay_amt: [
          {
            required: true,
            message: this.$t("problem.payAmountPlaceholder"),
            trigger: "blur",
          },
        ],
        fb_link: [
          {
            required: true,
            message: this.$t("problem.fbLinkPlaceholder"),
            trigger: "blur",
          },
        ],
        occur_time: [
          {
            type: "date",
            required: true,
            message: this.$t("problem.bugOccurPlaceholder"),
            trigger: "change",
          },
        ],
        appeal_type: [
          {
            required: true,
            message: this.$t("problem.rechargeTypePlaceholder"),
            trigger: "blur",
          },
        ],
        report_type: [
          {
            required: true,
            message: this.$t("problem.complaintsAndReportsPlaceholder"),
            trigger: "blur",
          },
        ],
        report_role_name: [
          {
            required: true,
            message: this.$t("problem.characterNamePlaceholder"),
            trigger: "blur",
          },
        ],
        report_server_name: [
          {
            required: true,
            message: this.$t("problem.serverNamePlaceholder"),
            trigger: "blur",
          },
        ],
        report_role_id: [
          {
            required: true,
            message: this.$t("problem.characterIDPlaceholder"),
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: this.$t("problem.decPlaceholder"),
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: this.$t("problem.contactPlaceholder"),
            trigger: "blur",
          },
        ],
        imgUrl: [
          {
            required: true,
            validator: this.fileMustUpload,
            trigger: "change",
          },
        ],
      };
    },
  },
  watch: {},
  created() {
    this.token = localStorage.getItem("token");
    this.getRouteInfo();
    this.getServerAndRoleInfo();
  },
  mounted() {},
  methods: {
    fileMustUpload(rule, value, callback) {
      if (this.ruleForm.imgUrl.length === 0) {
        // 未上传文件
        callback(this.$t("problem.atLeastOneFile"));
      }
      callback(); //不要忘记写这个callback
    },
    getRouteInfo() {
      let { type, appeal_type } = this.$route.query;
      this.type = Number(type);
      this.ruleForm.appeal_type = appeal_type || "";
    },
    handleReset() {
      this.$refs.ruleForm.resetFields();
      this.fileList = [];
      this.videoUrl = "";
      this.fileImgList = [];
      this.fileVideoList = [];
    },
    //获取游戏区服角色接口
    getServerAndRoleInfo() {
      let params = {
        token: this.token,
      };
      this.$http.post("/api/serverAndRoleInfo", params).then((res) => {
        if (res.code == 200) {
          this.servers_roles.game = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //判断登录类型清空区服角色数据
    handleLoginType(e) {
      if (Number(e) === 1) {
        this.ruleForm.server_id = "";
        this.ruleForm.role_id = "";
      } else {
        this.ruleForm.login_mode = "";
        this.ruleForm.email = "";
      }
    },
    //选择游戏
    handleGameChange(e) {
      let server = this.servers_roles.game.filter((item) => {
        return item.game_id === e;
      });
      if (server[0].server && server[0].server.length > 0) {
        this.servers_roles.server = server[0].server;
        this.servers_roles.roles = this.servers_roles.server[0].role;
        this.ruleForm.server_id = this.servers_roles.server[0].server_id;
        this.ruleForm.role_id = this.servers_roles.server[0].role[0].role_id;
      } else {
        this.servers_roles.server = "";
        this.servers_roles.roles = "";
        this.ruleForm.server_id = "";
        this.ruleForm.role_id = "";
      }
    },
    //选择区服
    handleServerChange(e) {
      let roles = this.servers_roles.server.filter((item) => {
        return item.server_id === e;
      });
      if (roles) {
        this.servers_roles.roles = roles[0].role;
        this.ruleForm.role_id = this.servers_roles.roles[0].role_id;
      }
    },
    handleSubmit(ev) {
      var _this = this;
      this.ruleForm.videoUrl = this.videoUrl;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.login_type == 1) {
            this.ruleForm.server_id = "";
            this.ruleForm.role_id = "";
          }
          if (this.ruleForm.login_type == 2) {
            this.ruleForm.role_id = "";
          }
          let occur_time = "";
          let pay_time = "";
          if (this.ruleForm.occur_time) {
            occur_time = this.$moment(this.ruleForm.occur_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          if (this.ruleForm.pay_time) {
            pay_time = this.$moment(this.ruleForm.pay_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          let params = {
            ..._this.ruleForm,
            occur_time,
            pay_time,
            type: this.type,
            token: _this.token,
          };
          // /api/ticket
          this.$http.post("/api/v2/createTicket", params).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.$router.push("/");
              this.handleReset();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSuccess(res, file) {
      if (res.code === 200) {
        this.fileList.push(res.data.url);
        this.ruleForm.imgUrl = this.fileList;
        this.$refs.ruleForm.validateField("imgUrl");
      }
    },
    handleRemove(file, fileList) {
      let list = [];
      fileList.forEach((item) => {
        list.push(item.response.data.url);
      });
      this.fileList = list;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(this.$t("problem.maxChooseImg"));
    },
    handlebeforeUpload(files, fileList) {
      return new Promise((resolve, reject) => {
        const suffix =
          files.type === "image/jpg" ||
          files.type === "image/png" ||
          files.type === "image/jpeg";
        const isLt1M = files.size / 1024 / 1024 < 20;
        if (!suffix) {
          this.$message.error(this.$t("problem.onlyJpg"));
          return reject(false);
        }
        if (!isLt1M) {
          this.$message.error(this.$t("problem.maxSize"));
          return reject(false);
        }
        // 调用[限制图片尺寸]函数
        return resolve(true);
      });
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeUpload(file) {
      if (file.type.startsWith("video/")) {
        // 限制文件大小为50MB
        const MAX_SIZE = 20 * 1024 * 1024;
        if (file.size > MAX_SIZE) {
          this.$message.error(this.$t("problem.maxSize"));
          return false;
        }
        // 文件大小符合要求，可以继续上传
        return true;
      } else {
        this.$message.error(this.$t("problem.uploadVideoMsg"));
        return false;
      }
    },
    onSuccess(res, file) {
      let _self = this;
      const video = document.createElement("video"); // 也可以自己创建video
      video.src = file.url; // url地址 url跟 视频流是一样的
      // file.url = videoDefCover; //设置默认封面，videoDefCover 为预制的默认封面，不需要可去除或替换成自己的
      let canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        console.log(video.clientWidth, video.clientHeight);
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        let _videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        _self.$set(file, "url", _videoFirstimgsrc); //重置文件的url为当前截取的封面，用于 el-upload展示
        video.remove();
        canvas.remove();
      };
      this.$message.success(this.$t("problem.uploadSuccess"));
      if (res.code === 200) {
        this.videoUrl = res.data.url;
      }
      // 处理上传成功后的逻辑，例如更新UI或者处理其他数据
    },
    onError(res, file, fileList) {
      this.$message.error(this.$t("problem.uploadFail") + res.msg);
      // 处理上传失败后的逻辑
    },
    onExceed(files, fileList) {
      this.$message.warning(this.$t("problem.onlyOneFile"));
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleVideoPreview(file) {
      this.$nextTick(() => {
        this.DialogVideoVisible = true;
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.DialogVideoVisible = false;
    },
    handleLoginMode(e) {
      if (e != 1) {
        this.ruleForm.email = "";
      }
    },
    //修改充值方式
    changeRechargeMode(e) {
      this.ruleForm.recharge_type = "";
      if (e != 3) {
        this.ruleForm.cardSerial = "";
      } else {
        this.ruleForm.bug_game_version = "";
        this.ruleForm.mobile_system_version = "";
        this.ruleForm.mobile_device = "";
      }
    },
    //修改充值问题类型
    changeRechargeType(e) {
      if (e != 1) {
        this.ruleForm.bug_game_version = "";
        this.ruleForm.mobile_system_version = "";
        this.ruleForm.mobile_device = "";
      }
      if (e != 2) {
        this.ruleForm.pay_time = "";
      }
      if (e != 3) {
        this.ruleForm.cardSerial = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}
/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .avatar {
  width: 80px;
  height: 80px;
}
/* 自定义进度条样式 */
/deep/.el-upload-list--picture-card .el-progress {
  width: 80px; /* 设置进度条的高度 */
}
/deep/ .el-progress-circle {
  width: 80px !important;
  height: 80px !important;
}
/deep/.el-upload-list--picture-card .el-upload-list__item-status-label i {
  position: absolute;
  left: 14px;
}
/deep/.el-select {
  width: 100%;
}
/deep/ .el-dialog__body {
  display: flex;
  justify-content: center;
}
.problem-body {
  margin-top: 45px;
  background-color: #fff;
  padding: 20px 15px;
  text-align: left;
  box-sizing: border-box;
}
</style>
