<template>
  <div class="main">
    <Header />
    <div class="main-center">
      <div class="main-list">
        <div class="main-item">
          <div class="carousel">
            <el-carousel arrow="always" height="200px">
              <el-carousel-item v-for="(item, index) in imgPC" :key="index">
                <div style="width: 100%; height: 100%">
                  <img
                    :src="item.img"
                    alt=""
                    style="width: 100%; height: 100%"
                  />
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="main-item">
          <div class="main-item-title">
            <div class="main-item-left">{{ $t("Main.selfService") }}</div>
            <div class="main-item-right"></div>
          </div>
          <div class="main-item-body">
            <div class="main-item-list">
              <div
                class="main-item-problem"
                v-for="item in searchList"
                :key="item.type"
                @click="handleRouter(item.url, item.type)"
              >
                <div class="main-item-problem-img">
                  <img :src="item.imgSrc" />
                </div>
                <div class="main-item-problem-text">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-item">
          <div class="main-item-title">
            <div class="main-item-left">
              {{ $t("Main.questionSubmission") }}
            </div>
            <div class="main-item-right"></div>
          </div>
          <div class="main-item-body">
            <div class="main-item-list">
              <div
                class="main-item-problem"
                v-for="item in problemList"
                :key="item.type"
                @click="handleClick(item.type)"
              >
                <div class="main-item-problem-img">
                  <img :src="item.imgSrc" />
                </div>
                <div class="main-item-problem-text">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-item">
          <div class="main-item-title">
            <div class="main-item-left">{{ $t("Main.normalQuestion") }}</div>
            <!-- <div class="main-item-right" @click="handleRouter('/question')">
              {{ $t("Main.more") }}<van-icon name="arrow" />
            </div> -->
          </div>
          <div class="main-item-body">
            <div class="main-item-list">
              <div
                class="main-item-problem"
                v-for="(item, index) in listRemark"
                :key="index"
                @click="handleRouter(`/question`, item.id)"
              >
                <div class="main-item-problem-img">
                  <img :src="item.imgSrc" />
                </div>
                <div class="main-item-problem-text">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <!-- <van-cell-group>
              <van-cell title="账号问题" :to="'/question?type=1'" is-link />
              <van-cell title="充值问题" :to="'/question?type=2'" is-link />
              <van-cell title="活动问题" :to="'/question?type=3'" is-link />
              <van-cell title="bug问题" :to="'/question?type=4'" is-link />
              <van-cell title="申诉问题" :to="'/question?type=5'" is-link />
              <van-cell title="其他问题" :to="'/question?type=6'" is-link />
              <van-cell
                :title="item.problem"
                :to="`/detail?d=${item.id}`"
                is-link
                v-for="(item, index) in PList"
                :key="index"
              />
            </van-cell-group> -->
          </div>
        </div>
      </div>
    </div>
    <ShowLanguageDialog ref="showLanguageDialog" />
    <Kefu />
  </div>
</template>

<script>
import Header from "@/components/Header";
import ShowLanguageDialog from "@/components/showLanguage.vue";
import Kefu from "@/components/kefu";
export default {
  name: "",
  mixins: [],
  components: { Header, ShowLanguageDialog, Kefu },
  props: {},
  data() {
    return {
      PList: [], //常见问题列表
      imgPC: [], //电脑端轮播图
      banner_mobile: [], //手机端轮播图
      listData: {},
    };
  },
  computed: {
    problemList() {
      return [
        //登录问题
        {
          type: 6,
          name: this.$t("Main.problem6"),
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        // {
        //   type: 1,
        //   name: this.$t("Main.problem1"),
        //   imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        // },
        //
        //充值问题
        {
          type: 2,
          name: this.$t("Main.problem2"),
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        //活动问题
        // {
        //   type: 3,
        //   name: this.$t("Main.problem3"),
        //   imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        // },
        //bug问题
        {
          type: 4,
          name: this.$t("Main.problem4"),
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        //申诉问题
        {
          type: 5,
          name: this.$t("Main.problem5"),
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        //投诉举报问题
        {
          type: 7,
          name: this.$t("Main.problem7"),
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        //其他问题
        {
          type: 8,
          name: this.$t("Main.problem8"),
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
      ];
    },
    searchList() {
      return [
        {
          type: 1,
          name: this.$t("Main.setPassword"),
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
          url: "/setPassword",
        },
        // {
        //   type: 2,
        //   name: "角色查询",
        //   imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        //   url: "/roleSearch",
        // },
        {
          type: 3,
          name: this.$t("Main.banSearch"),
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
          url: "/closureSearch",
        },
      ];
    },
    list() {
      return [
        {
          name: this.$t("Main.problem1"),
          id: 1,
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        {
          name: this.$t("Main.problem2"),
          id: 2,
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        {
          name: this.$t("Main.problem3"),
          id: 3,
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        {
          name: this.$t("Main.problem4"),
          id: 4,
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        {
          name: this.$t("Main.problem5"),
          id: 5,
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
        {
          name: this.$t("Main.problem8"),
          id: 6,
          imgSrc: require("@/assets/img/problem/zhanghao.webp"),
        },
      ];
    },
    listRemark: {
      get() {
        return this.list.filter((item) => {
          return item.id && this.listData[item.id];
        });
      },
      set() {
        return this.list.filter((item) => {
          return item.id && this.listData[item.id];
        });
      },
    },
  },
  watch: {},
  created() {
    this.getBannerData();
    this.getProblemData();
  },
  mounted() {},
  methods: {
    getBannerData() {
      this.$http.post("/api/banner", {}).then((res) => {
        this.imgPC = res.data.banner_pc;
        this.banner_mobile = res.data.banner_mobile;
      });
    },
    handleLang() {
      this.$refs.showLanguageDialog.showDialog = true;
    },
    handleClick(type) {
      this.$router.push({
        path: "/problem",
        query: { type },
      });
    },
    handleRouter(url, num) {
      let type;
      if (url === "/setPassword") {
        type = num === 1 ? num : 0;
      } else {
        type = num || null;
      }

      this.$router.push({ path: url, query: { type } });
    },
    getProblemData() {
      // this.$http.post("/api/v2/getIndexProblem", {}).then((res) => {
      //   if (res.code === 200) {
      //     this.PList = res.data.list;
      //   }
      // });
      this.$http.post("/api/v2/getProblemList").then((res) => {
        if (res.code === 200) {
          this.listData = res.data.list;
          // 过滤 list，只保留在 listData 中存在的 id
          const filteredCategories = this.list.filter((category) => {
            return category.id && res.data.list[category.id];
          });
          this.listRemark = filteredCategories;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-carousel__arrow,
/deep/.el-carousel__indicators {
  display: none;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.main {
  width: 100%;
}

.main-center {
  margin-top: 40px;
}

.main-item {
  padding: 5px 0px 0;
  background-color: #fff;
  margin-bottom: 10px;
}

.main-item-title {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  height: 46px;
  line-height: 46px;
  font-size: 15px;
  padding: 0 15px;
}

.main-item-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.main-item-problem {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 25%;
  overflow: hidden;
}

.main-item-problem-text {
  font-size: 13px;
  height: 30px;
}

.main-item-problem-img img {
  width: 50px;
  cursor: pointer;
}

.main-item-right {
  font-size: 12px;
  cursor: pointer;
  color: rgb(17, 136, 255);
}

@media screen and (max-width: 1024px) {
  .main-header {
    width: 100%;
  }
}
</style>
