//登录 Login
export const Login = {
  accountPlaceholder: '请输入账号',
  passwordPlaceholder: '请输入密码',
  forgetPassword: '忘记密码',
  login: '登录',
  other: '其他登录方式',
  emailPlaceholder: '请输入邮箱',
  codePlaceholder: '请输入验证码',
  getCode: '获取验证码',
  emailCheck: '您还未填写邮箱！',
  newPassword: '输入新密码',
  repeatPassword: '重复新密码',
  changePassword: '修改密码',
}
//首页Header
export const Header = {
  myTicket: '我的工单'
}

//子页面NavBar
export const NavBar = {
  loginOut: '退出登录',
  isLoginOut: '是否确认退出登录',
  confirm: '确定',
  cancel: '取消'
}

//首页Main
export const Main = {
  selfService: '自助工具',
  questionSubmission: '问题提交',
  normalQuestion: '常见问题',
  more: '更多',
  problem6: '登录问题',
  problem1: '账号问题',
  problem2: '充值问题',
  problem3: '活动问题',
  problem4: 'bug问题',
  problem5: '申诉问题',
  problem7: '投诉举报',
  problem8: '其他',
  setPassword: '修改密码',
  banSearch: '封禁查询'
}
//我的工单
export const MyTicket = {
  title: '我的工单',
  rechargeType: '充值问题类型',
  paySuccessTime: '支付成功时间',
  payAmount: '付款金额',
  FBLink: 'FB活动链接',
  occurrenceTime: '发生时间',
  issueType: '申诉问题类型',
  complaintType: '投诉举报类型',
  characterName: '被举报人角色名',
  serverName: '被举报人区服名',
  characterID: '被举报人角色ID',
  ImageDetails: '图片详情',
  VideoDetails: '视频详情',
  noUseVideo: '您的浏览器不支持此视频格式。',
  processed: '已处理',
  processing: '处理中',
  customerServiceResponse: '客服回复',
  all: '全部',
  abnormalRecharge: '充值异常',
  itemsNotReceived: '道具未到账',
  RefundIssue: '退款问题',
  BlockIssue: '封号问题',
  MuteIssue: '禁言问题',
  OtherIssues: '其他问题',
  IllegalSpeech: '违规言论',
  IllegalCharacterName: '违规角色名',
  OtherViolations: '其他违规',
  noReply:'暂无客服回复'
}

//账号封禁查询closureSearch
export const closureSearch = {
  title: '账号封禁查询',
  GameName: '游戏名称',
  BanType: "封禁类型",
  BanReason: "封禁原因",
  BanTime: "封禁时间",
  UnblockOrder: "解封订单",
  UnblockOrderPendingPayment: '存在待支付解封订单',
  None: '无',
  GoToUnblock: '去解封',
  GoToAppeal: '去申诉',
}

//常见问题详情detail
export const detail = {
  title: '问题详情',
}

//问题提交problem
export const problem = {
  rechargeMethod: '充值方式',
  rechargeMethodPlaceholder: '请选择充值方式',
  applePurchase: '苹果内购',
  googlePurchase: '谷歌内购',
  WebPurchase: '网页充值',
  rechargeType: "充值问题类型",
  rechargeTypePlaceholder: '请选择充值问题类型',
  abnormalRecharge: '充值异常',
  itemsNotReceived: '道具未到账',
  cardRecharge: '卡充异常',
  cardNum: '卡号',
  cardNumPlaceholder: '请输入卡号',
  paySuccessTime: '支付成功时间',
  paySuccessTimePlaceholder: '请选择支付成功时间',
  payAmount: '付款金额',
  payAmountPlaceholder: '请输入付款金额',
  LoginType: '登录问题类型',
  LoginTypePlaceholder: '请选择问题登录类型',
  AccountProblem: '账号登录问题',
  selectServerProblem: '选择区服异常',
  roleLoginProblem: '角色登录问题',
  loginMethod: '登录方式',
  loginMethodPlaceholder: '请选择登录方式',
  emailLogin: '邮箱登录',
  FbLogin: 'FB登录',
  googleLogin: '谷歌登录',
  guestLogin: '游客登录',
  emailPlaceholder: '请输入邮箱',
  fbLink: 'FB活动链接',
  fbLinkPlaceholder: '请输入FB活动链接',
  bugOccur: 'bug发生时间',
  bugOccurPlaceholder: '请选择bug发生时间',
  gameVersion: '游戏版本',
  gameVersionExample: '例如：1.0.318(点击手机设置-应用管理)',
  phoneSystemVersion: '手机系统版本号',
  phoneSystemVersionExample: '例如：安卓11',
  mobileDevice: '手机设备型号',
  mobileDeviceExample: '例如：Huawei p40',
  appealType: '申诉问题类型',
  appealTypePlaceholder: '请选择申诉问题类型',
  blockIssue: '封号问题',
  muteIssue: '禁言问题',
  otherIssues: '其他问题',
  complaintsAndReports: '投诉举报',
  complaintsAndReportsPlaceholder: '请选择投诉举报类型',
  IllegalSpeech: '违规言论',
  IllegalCharacterName: '违规角色名',
  OtherViolations: '其他违规',
  characterName: '被举报人角色名',
  characterNamePlaceholder: '请输入被举报人角色名',
  serverName: '被举报人区服名',
  serverNamePlaceholder: '请输入被举报人区服名',
  characterID: '被举报人角色ID',
  characterIDPlaceholder: '请输入被举报人角色ID',

  chooseGame: 'Pilih game',
  chooseGameplaceholder: 'Silakan pilih game',
  chooseServer: 'Pilih Server',
  chooseServerplaceholder: 'Silakan pilih server',
  chooseRole: 'Pilih Peran',
  chooseRoleplaceholder: 'Silakan pilih peran',
  problemStyle: 'Jenis pertanyaan',
  problemStyleplaceholder: 'Silakan pilih jenis pertanyaan',
  decPlaceholder: 'Pilih deskripsi masalah',
  contactPlaceholder: '请输入您的Zalo号码',
  decProblem: 'Jelaskan masalahnya',
  uploadImg: 'Unggah gambar',
  confirmUpload: 'Konfirmasi upload',
  confirmUploadDec: 'Hanya jpg/png yang dapat diunggah.',
  uploadVideo: 'Unggah file video',
  confirmUploadVideoDec: 'Hanya MP yang dapat diunggah.',
  contact: 'Zalo',
  confirm: 'Kirim Informasi',
  maxChooseImg: 'Saat ini hanya maksimal 3 file yang dapat dipilih',
  onlyJpg: 'Hanya jpg/png yang dapat diunggah.',
  maxSize: 'Unggahan file tidak boleh melebihi 20MB',
  uploadVideoMsg: "Silakan unggah file video!",
  uploadSuccess: 'Upload berhasil',
  uploadFail: 'Upload gagal,',
  onlyOneFile: 'Saat ini hanya maksimal satu file yang dapat dipilih',
}
