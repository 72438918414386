<template>
  <div class="navBar">
    <van-nav-bar
      :title="title"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft(routeUrl)"
      @click-right="onClickRight"
    >
      <div slot="right">
        <div class="navBar-right">
          <!-- <img
            class="language-img"
            @click="openLanguages"
            src="@/assets/img/earth.png"
            alt=""
          /> -->
          <div class="logout" @click="handleLogout" v-if="logout">
            {{ $t("NavBar.loginOut") }}
          </div>
        </div>
      </div>
    </van-nav-bar>
    <ShowLanguageDialog ref="showLanguageDialog" />
  </div>
</template>

<script>
import ShowLanguageDialog from "@/components/showLanguage.vue";
import { Dialog } from "vant";
export default {
  name: "",
  mixins: [],
  components: { ShowLanguageDialog },
  props: {
    title: {
      type: String,
    },
    routeUrl: {
      type: String,
    },
    logout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onClickLeft(routeUrl) {
      // Toast('返回');
      this.$router.push(routeUrl);
    },
    onClickRight() {
      // Toast("按钮");
    },
    openLanguages() {
      this.$refs.showLanguageDialog.showDialog = true;
    },
    handleLogout() {
      Dialog.confirm({
        title: this.$t("NavBar.loginOut"),
        message: this.$t("NavBar.isLoginOut"),
        confirmButtonText: this.$t("NavBar.confirm"),
        cancelButtonText: this.$t("NavBar.cancel"),
      })
        .then(() => {
          this.$router.push("/login");
          localStorage.removeItem("token");
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped lang="scss">
.language-img {
  width: 32px;
  cursor: pointer;
}
.navBar-right {
  display: flex;
  align-items: center;
}
.logout {
  color: #6d8fc7;
  margin-left: 0px;
}
</style>
