<template>
  <div class="detail">
    <NavBar title="问题详情" routeUrl="/myTicket" />
    <div class="detail-page">
      <div class="detail-title">什么时候开新服</div>
      <div class="detail-content">
        <p>
          开服时间是策划大大根据游戏实际运营情况进行安排的，目前客服也无法提前获取的哦~~
        </p>
        <p>建议您多留意游戏内公告或邮件开服信息~</p>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "TickerInfo",
  mixins: [],
  components: { NavBar },
  props: {},
  data() {
    return {
      typeName: ["账号问题", "充值问题", "活动问题", "bug问题", "申诉问题"],
      ticketInfo: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    console.log(this.$route.query.id);
  },
  mounted() {},
  methods: {
    getData() {},
  },
};
</script>

<style scoped lang="scss">
.detail {
  height: 100%;
}
.detail-page {
  height: 100%;
  background-color: #fff;
  padding: 65px 15px 20px;
  text-align: left;
  box-sizing: border-box;
}
.detail-title {
  font-size: 20px;
  font-weight: 700;
}
.detail-content {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.5;
  padding: 20px 0;
  text-align: justify;
}
</style>
