<template>
  <div class="role">
    <NavBar title="角色查询" routeUrl="/" />
    <div class="role-body">
      <van-search
        placeholder="请输入搜索关键词"
        v-model="value"
        show-action
        @search="onSearch"
        @cancel="onCancel"
        @input="removeSpaces"
      >
        <div slot="action" @click="onSearch">搜索</div>
      </van-search>
      <div class="role-item" v-if="roleData.length > 0">
        <div class="role-info" v-for="(item, index) in roleData" :key="index">
          <div>游戏名称：{{ item.game_name }}</div>
          <div>区服：{{ item.server_name }}</div>
          <div>角色名：{{ item.cp_role_name }}</div>
          <div>角色等级：{{ item.role_level }}</div>
          <div>创建时间：{{ item.role_time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "question",
  mixins: [],
  components: { NavBar },
  props: {},
  data() {
    return {
      value: "",
      token: localStorage.getItem("token"),
      roleData: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onSearch() {
      let params = {
        account: this.value,
        token: this.token,
      };
      if (!this.value) {
        this.$message.error("请输入搜索关键词");
        return;
      }
      this.$http.post("/api/v2/searchUserInfo", params).then((res) => {
        if (res.code === 200) {
          this.roleData = res.data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onCancel() {
      this.value = "";
    },
    removeSpaces(inputElement) {
      var inputValue = inputElement;
      var cleanedValue = inputValue.replace(/\s+/g, "");
      this.value = cleanedValue;
    },
  },
};
</script>

<style scoped lang="scss">
.role-body {
  margin-top: 45px;
}
.role-item {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  text-align: left;
  color: gray;
  font-size: 14px;
}

.role-info {
  padding: 0 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
}

.role-info:last-child {
  border-bottom: none;
}
.role-info div {
  margin-bottom: 10px;
}
</style>
