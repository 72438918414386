<template>
  <div>
    <a href="javascript:;" class="login_type_2" @click="loginWithFacebook">
      <img class="facebook" src="@/assets/img/facebook.png" />
    </a>
  </div>
</template>
<script defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
<script>
// import FB from "facebook-js-sdk";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    fb_appid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v3.3";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
  mounted() {},
  methods: {
    loginWithFacebook() {
      FB.init({
        appId: this.fb_appid,
        cookie: true,
        xfbml: true,
        version: "v11.0",
      });
      FB.login((response) => {
        let res = JSON.parse(JSON.stringify(response));
        if (res.authResponse?.accessToken) {
          this.Login(res.authResponse.accessToken);
        } else {
          this.$message.error(res.status);
        }
      });
    },
    Login(access_token) {
      let game_id = this.$route.query.g;
      let params = {
        game_id,
        access_token: access_token,
      };
      this.$http.post("/api/login", params).then((res) => {
        if (res.data.code === 200) {
          let token = res.data.data.token;
          localStorage.setItem("token", JSON.stringify(token));
          this.selectServe = true;
          this.$router.push("/consume");
          this.$message.success(res.data.msg);
          setTimeout(() => {
            this.showLoading = false;
          }, 2000);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.facebook {
  width: 40px;
  height: 40px;
}
</style>
