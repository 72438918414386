<template>
  <div class="showLanguageDialog">
    <el-dialog
      :visible.sync="showDialog"
      :width="$isMobile ? '90%' : '500px'"
      center
      :show-close="false"
      append-to-body
    >
      <div class="language_list">
        <div
          class="language_item"
          v-for="(item, index) in options"
          :key="index"
          @click="changeLang(item.value)"
          :style="{ color: item.value == lang ? 'blue' : '' }"
        >
          {{ item.label }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      showDialog: false,
      lang: "VI",
      options: [
        {
          value: "VI",
          label: "Tiếng Việt",
        },
        // {
        //   value: "TH",
        //   label: "แบบไทย",
        // },
        // {
        //   value: "ID",
        //   label: "Indonesia",
        // },
        // {
        //   value: "EN",
        //   label: "English",
        // },
        {
          value: "ZH",
          label: "中文",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.lang = localStorage.getItem("locale") || "VI";
  },
  mounted() {},
  methods: {
    changeLang(e) {
      this.lang = localStorage.getItem("locale") || "VI";
      if (this.lang === "VI") {
        this.lang = e;
        this.$i18n.locale = e;
      } else {
        this.lang = e;
        this.$i18n.locale = e;
      }
      localStorage.setItem("locale", this.lang);
      this.$store.state.lang = this.lang;
      this.showDialog = false;
    },
    openDialog() {
      if (!this.$isMobile) {
        return;
      }
      this.showDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.language_item {
  height: 60px;
  line-height: 60px;
}
/deep/.el-dialog {
  border-radius: 30px;
  margin-top: 25vh !important;
}
/deep/.el-dialog__header {
  padding: 0;
}

/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}
.language_list {
  text-align: center;
}
.language_item {
  cursor: pointer;
}
.language_item:hover {
  transform: scale(1.1);
}
</style>
