//登录 Login
export const Login = {
  accountPlaceholder: 'Nhập tài khoản',
  passwordPlaceholder: 'Nhập mật khẩu',
  forgetPassword: 'Quên mật khẩu',
  login: 'Đăng nhập',
  other: 'Phương thức đăng nhập khác',
  emailPlaceholder: 'Nhập email',
  codePlaceholder: 'Nhập OTP',
  getCode: 'Nhận OTP',
  emailCheck: 'Bạn chưa điền email!',
  newPassword: 'Mật khẩu mới',
  repeatPassword: 'Nhập lại mật khẩu mới',
  changePassword: 'Đổi mật khẩu',
}

//首页Header
export const Header = {
  myTicket: 'Ticket của tôi'
}

//子页面NavBar
export const NavBar = {
  loginOut: 'Đăng xuất',
  isLoginOut: 'Xác nhận đăng xuất?',
  confirm: 'Xác nhận',
  cancel: 'Hủy bỏ'
}

//首页Main
export const Main = {
  selfService: 'Công cụ tự trợ giúp',
  questionSubmission: 'Gửi ticket',
  normalQuestion: 'Câu hỏi thường gặp',
  more: 'Xem thêm',
  problem6: 'Vấn đề đăng nhập',
  problem1: 'Vấn đề tài khoản',
  problem2: 'Vấn đề nạp',
  problem3: 'Vấn đề hoạt động',
  problem4: 'Vấn đề bug',
  problem5: 'Vấn đề khiếu nại',
  problem7: 'Tố cáo & Cấm chat',
  problem8: 'Khác',
  setPassword: 'Đổi mật khẩu',
  banSearch: 'Kiểm tra khóa'
}
//我的工单
export const MyTicket = {
  title: 'Ticket của tôi',
  rechargeType: 'Loại vấn đề nạp',
  paySuccessTime: 'Thời gian thanh toán thành công',
  payAmount: 'Số tiền thanh toán',
  FBLink: 'Link hoạt động FB',
  occurrenceTime: 'Thời gian xảy ra',
  issueType: 'Loại vấn đề khiếu nại',
  complaintType: 'Loại khiếu nại và tố cáo',
  characterName: 'Tên nhân vật bị tố cáo',
  serverName: 'Tên server bị tố cáo',
  characterID: 'ID nhân vật bị tố cáo',
  ImageDetails: 'Hình ảnh chi tiết',
  VideoDetails: 'Video chi tiết',
  noUseVideo: 'Trình duyệt của bạn không hỗ trợ định dạng video này',
  processed: 'Đã xử lý',
  processing: 'Đang xử lý',
  closed: 'Đã đóng',
  customerServiceResponse: 'CSKH phản hồi',
  all: 'Tất cả',
  abnormalRecharge: 'Nạp bất thường',
  itemsNotReceived: 'Không nhận được vật phẩm',
  RefundIssue: 'Vấn đề refund',
  BlockIssue: 'Vấn đề khóa tài khoản',
  MuteIssue: 'Vấn đề cấm chat',
  OtherIssues: 'Vấn đề khác',
  IllegalSpeech: 'Phát ngôn vi phạm',
  IllegalCharacterName: 'Tên nhân vật vi phạm',
  OtherViolations: 'Vi phạm khác',
  noReply: 'Tạm chưa có CSKH phản hồi'
}

//账号封禁查询closureSearch
export const closureSearch = {
  title: 'Kiểm tra khóa tài khoản',
  GameName: 'Tên sản phẩm',
  BanType: "Loại",
  BanReason: "Nguyên nhân khóa",
  BanTime: "Thời gian khóa",
  UnblockOrder: "Đơn hàng mở khóa",
  UnblockOrderPendingPayment: 'Có đơn hàng cần thanh toán để mở khóa',
  None: 'Không',
  GoToUnblock: 'Đến mở khóa',
  GoToAppeal: 'Đến tố cáo',
}

//常见问题详情detail
export const detail = {
  title: 'Chi tiết vấn đề',
}

//问题提交problem
export const problem = {
  rechargeMethod: 'Phương thức nạp',
  rechargeMethodPlaceholder: 'Xin chọn phương thức nạp',
  applePurchase: 'Nạp in-app Apple',
  googlePurchase: 'Nạp in-app CHPlay',
  WebPurchase: 'Nạp webpay',
  rechargeType: "Loại vấn đề nạp",
  rechargeTypePlaceholder: 'Xin chọn loại vấn đề nạp',
  abnormalRecharge: 'Nạp bất thường',
  itemsNotReceived: 'Không nhận được vật phẩm',
  cardRecharge: 'Thẻ nạp bất thường',
  cardNum: 'Số thẻ',
  cardNumPlaceholder: 'Xin nhập số thẻ',
  paySuccessTime: 'Thời gian thanh toán thành công',
  paySuccessTimePlaceholder: 'Xin chọn thời gian thanh toán thành công',
  payAmount: 'Số tiền thanh toán',
  payAmountPlaceholder: 'Xin nhập số tiền thanh toán',
  LoginType: 'Loại vấn đề đăng nhập',
  LoginTypePlaceholder: 'Xin chọn loại vấn đề đăng nhập',
  AccountProblem: 'Vấn đề đăng nhập tài khoản',
  selectServerProblem: 'Chọn server bất thường',
  roleLoginProblem: 'Vấn đề đăng nhập nhân vật',
  loginMethod: 'Phương thức đăng nhập',
  loginMethodPlaceholder: 'Xin chọn phương thức đăng nhập',
  emailLogin: 'Đăng nhập Email',
  FbLogin: 'Đăng nhập Facebook',
  googleLogin: 'Đăng nhập Google',
  guestLogin: 'Đăng nhập nhanh',
  emailPlaceholder: 'Nhập email',
  fbLink: 'Link hoạt động Facebook',
  fbLinkPlaceholder: 'Nhập link hoạt động facebook',
  bugOccur: 'Thời gian xảy ra',
  bugOccurPlaceholder: 'Xin chọn thời gian xảy ra',
  gameVersion: 'Phiên bản game',
  gameVersionExample: 'Ví dụ: 1.0.318 (mở phần Cài đặt trong điện thoại - Quản lý ứng dụng)',
  phoneSystemVersion: 'Số phiên bản hệ thống điện thoại',
  phoneSystemVersionExample: 'Ví dụ: Android 11',
  mobileDevice: 'Thông tin thiết bị',
  mobileDeviceExample: 'Ví dụ: Huawei p40',
  deviceInfo: 'Cấu hình thiết bị',
  deviceInfoExample: 'Ví dụ: 4GB 256 GB',
  network: 'Mạng sử dụng',
  networkExample: 'Ví dụ: 5G Viettel',
  appealType: 'Loại vấn đề khiếu nại',
  appealTypePlaceholder: 'Xin chọn loại vấn đề khiếu nại',
  blockIssue: 'Khóa tài khoản',
  muteIssue: 'Cấm chat',
  otherIssues: 'Vấn đề khác',
  complaintsAndReports: 'Khiếu nại và tố cáo',
  complaintsAndReportsPlaceholder: 'Xin chọn loại khiếu nại và tố cáo',
  IllegalSpeech: 'Phát ngôn vi phạm',
  IllegalCharacterName: 'Tên nhân vật vi phạm',
  ReportCheating: 'Tố cáo hack/cheat',
  OtherViolations: 'Vi phạm khác',
  characterName: 'Tên nhân vật bị tố cáo',
  characterNamePlaceholder: 'Xin nhập tên nhân vật bị tố cáo',
  serverName: 'Tên server của người bị tố cáo',
  serverNamePlaceholder: 'Xin nhập tên server của người bị tố cáo',
  characterID: 'ID nhân vật bị tố cáo',
  characterIDPlaceholder: 'Xin nhập ID nhân vật bị tố cáo',

  chooseGame: 'Chọn game',
  chooseGameplaceholder: 'Xin chọn game',
  chooseServer: 'Chọn server',
  chooseServerplaceholder: 'Xin chọn server',
  chooseRole: 'Chọn nhân vật',
  chooseRoleplaceholder: 'Xin chọn nhân vật',
  problemStyle: 'Loại vấn đề',
  problemStyleplaceholder: 'Xin chọn loại vấn đề',
  decPlaceholder: 'Xin chọn Mô tả vấn đề',
  contactPlaceholder: 'Nhập sdt Zalo của bạn',
  decProblem: 'Mô tả vấn đề',
  uploadImg: 'Upload hình ảnh',
  confirmUpload: 'Xác nhận upload',
  confirmUploadDec: 'Chỉ có thể tải lên file định dạng jpg/png và không được quá 20MB',
  uploadVideo: 'Upload Video clip',
  confirmUploadVideoDec: 'Chỉ có thể tải lên file mp4 (video clip)',
  confirmUploadVideoDec2: 'Lưu ý: tính năng quay video của 1 số trình giả lập Android (LDPlayer...) có thể phát sinh lỗi không hiển thị nội dung video khi đăng tải, bạn vui lòng sử dụng chế độ quay phim màn hình trên thiết bị di động để đảm bảo hiển thị.',
  contact: 'Zalo',
  confirm: 'Gửi biểu mẫu',
  maxChooseImg: 'Hiện chỉ được chọn tối đa 3 file',
  onlyJpg: 'Chỉ có thể tải lên hình ảnh định dạng jpg/png!',
  maxSize: 'File tải lên không được quá 20MB!',
  uploadVideoMsg: 'Xin tải lên file video clip!',
  uploadSuccess: 'Upload thành công!',
  uploadFail: 'Upload thất bại,',
  onlyOneFile: 'Hiện chỉ được chọn tối đa 1 file',
  atLeastOneFile: 'Chọn ít nhất 1 hình',
}