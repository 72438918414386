<template>
  <div class="question">
    <!-- <Header /> -->
    <NavBar :title="$t('Main.normalQuestion')" routeUrl="/" />
    <div class="qusetion-body">
      <van-tabs v-model="active" animated swipe-threshold="2">
        <van-tab
          v-for="(item, index) in listRemark"
          :key="index"
          :title="item.name"
        >
          <van-cell
            v-if="active == 2"
            :title="$t('problem.chooseGame')"
            arrow-direction="down"
            is-link
            :value="game.name"
            @click="handleSelect"
          />
          <div style="margin-top: 10px"></div>
          <van-cell-group>
            <van-cell
              v-for="(i, ind) in listData[item.id]"
              :key="ind"
              :title="i.problem"
              is-link
              @click="handleClick(i.id)"
            />
          </van-cell-group>
        </van-tab>
      </van-tabs>
      <Kefu />
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import NavBar from "@/components/NavBar.vue";
import Kefu from "@/components/kefu";
export default {
  name: "question",
  mixins: [],
  components: { Header, NavBar, Kefu },
  props: {},
  data() {
    return {
      active: 3,
      listData: {},
      token: localStorage.getItem("token"),
      activeGameList: [], //活动问题列表
      game: {
        id: "",
        name: "",
      },
      show: false,
      actions: [],
    };
  },
  computed: {
    list() {
      return [
        {
          name: this.$t("Main.problem1"),
          id: 1,
        },
        {
          name: this.$t("Main.problem2"),
          id: 2,
        },
        {
          name: this.$t("Main.problem3"),
          id: 3,
        },
        {
          name: this.$t("Main.problem4"),
          id: 4,
        },
        {
          name: this.$t("Main.problem5"),
          id: 5,
        },
        {
          name: this.$t("Main.problem8"),
          id: 6,
        },
      ];
    },
    listRemark: {
      get() {
        return this.list.filter((item) => {
          return item.id && this.listData[item.id];
        });
      },
      set() {
        return this.list.filter((item) => {
          return item.id && this.listData[item.id];
        });
      },
    },
  },
  watch: {},
  created() {},
  mounted() {
    let type = {
      1: 1,
      2: 2,
      3: 3,
      6: 4,
    };
    this.active = this.$route.query.type
      ? type[Number(this.$route.query.type)] - 1
      : 0;
    this.getData();
  },
  methods: {
    handleClick(e) {
      this.$router.push({ path: "/detail", query: { d: e } });
    },
    handleSelect() {
      this.show = true;
    },
    onSelect(item) {
      this.show = false;
      this.game = item;
      const filteredData = this.activeGameList.filter(
        (i) => i.game_id === this.game.id
      );
      this.listData[3] = filteredData;
    },
    getData() {
      let params = {
        type: this.active + 1,
      };
      this.$http.post("/api/v2/getProblemList", params).then((res) => {
        if (res.code === 200) {
          this.listData = res.data.list;
          // 过滤 list，只保留在 listData 中存在的 id
          const filteredCategories = this.list.filter((category) => {
            return category.id && res.data.list[category.id];
          });
          this.listRemark = filteredCategories;
          this.getServerAndRoleInfo();
        }
      });
    },
    //获取游戏区服角色接口
    getServerAndRoleInfo() {
      let params = {
        token: this.token,
      };
      this.$http.post("/api/v2/gameList", params).then((res) => {
        if (res.code == 200) {
          this.actions = res.data.map((action) => ({
            id: action.game_id,
            name: action.game_name,
          }));
          this.game = this.actions[0];
          this.activeGameList = this.listData[3];
          const filteredData = this.activeGameList.filter(
            (item) => item.game_id === this.game.id
          );
          this.listData[3] = filteredData;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.qusetion-body {
  margin-top: 45px;
}
.van-cell-group__title {
  text-align: left;
}
</style>
