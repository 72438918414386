<template>
  <div class="account">
    <!-- <Header /> -->
    <NavBar title="账号问题" routeUrl="/" />
    <div class="account-body">
      <van-cell-group>
        <van-cell title="账号问题" :to="'/problem?type=1'" is-link />
        <van-cell title="修改密码" :to="'/setPassword'" is-link />
        <van-cell title="账号游戏角色查询" :to="'/roleSearch'" is-link />
        <van-cell title="账号封禁查询" :to="'/closureSearch'" is-link />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import NavBar from "@/components/NavBar.vue";
export default {
  name: "question",
  mixins: [],
  components: { Header, NavBar },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.account-body {
  margin-top: 45px;
}
</style>
