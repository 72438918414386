<template>
  <div class="detail">
    <NavBar
      :title="$t('detail.title')"
      :routeUrl="'/question?type=' + info.type"
    />
    <div class="detail-page">
      <div class="detail-title">{{ info.problem }}</div>
      <div class="detail-content" v-html="info.answer"></div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "detail",
  mixins: [],
  components: { NavBar },
  props: {},
  data() {
    return {
      problem_id: "",
      info: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    getData() {
      this.problem_id = this.$route.query.d ? this.$route.query.d : "";
      console.log(this.problem_id);

      let params = {
        problem_id: this.problem_id,
      };
      this.$http.post("/api/v2/getProblem", params).then((res) => {
        if (res.code === 200) {
          this.info = res.data.info;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.detail {
  height: 100%;
}
.detail-page {
  height: auto;
  background-color: #fff;
  padding: 65px 15px 20px;
  text-align: left;
  box-sizing: border-box;
}
.detail-title {
  font-size: 20px;
  font-weight: 700;
}
.detail-content {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.5;
  padding: 20px 0;
  text-align: justify;
}
</style>
