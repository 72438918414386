<template>
  <div class="closure">
    <NavBar :title="$t('closureSearch.title')" routeUrl="/" />
    <div class="closure-body" v-if="closure.length > 0">
      <div class="closure-item" v-for="item in closure" :key="item.id">
        <div>
          <span class="closure-left">{{ $t("closureSearch.GameName") }}</span
          >：
          {{ item.game_name }}
        </div>
        <div>
          <span class="closure-left">{{ $t("closureSearch.BanType") }}</span
          >：
          {{ item.action_info }}
        </div>
        <!-- <div>
          <span class="closure-left">{{ $t("closureSearch.BanReason") }}</span
          >：
          {{ item.reason }}
        </div> -->
        <div>
          <span class="closure-left">{{ $t("closureSearch.BanTime") }}</span
          >： {{ item.start_time }} - {{ item.end_time }}
        </div>
        <div v-if="item.is_closure_order === 1">
          <span>
            <span class="closure-left">{{
              $t("closureSearch.UnblockOrder")
            }}</span
            >：
            {{ $t("closureSearch.UnblockOrderPendingPayment") }}
          </span>
          <span class="go-closure" @click="handleClick(item)">
            {{ $t("closureSearch.GoToUnblock") }}
          </span>
        </div>
        <div v-else>
          <span>
            <span class="closure-left">{{
              $t("closureSearch.UnblockOrder")
            }}</span
            >： {{ $t("closureSearch.None") }}</span
          >
          <span class="go-closure" @click="handleClick(item)">
            {{ $t("closureSearch.GoToAppeal") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "question",
  mixins: [],
  components: { NavBar },
  props: {},
  data() {
    return {
      token: localStorage.getItem("token"),
      closure: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http
        .post("/api/v2/searchClosureInfo", { token: this.token })
        .then((res) => {
          if (res.code === 200) {
            this.closure = res.data.data;
          }
        });
    },
    handleClick(e) {
      if (e.is_closure_order) {
        window.open(e.auto_login, "blank");
      } else {
        this.$router.push({
          path: "/problem",
          query: {
            type: 5,
            appeal_type: 1,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.closure-body {
  margin-top: 45px;
}
.closure-item {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  text-align: left;
  color: gray;
  font-size: 14px;
}
.closure-item div {
  margin-bottom: 10px;
}
.go-closure {
  margin-left: 20px;
  color: #1188ff;
  cursor: pointer;
}
.closure-left {
  font-weight: bold;
  color: black;
}
</style>
