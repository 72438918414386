<template>
  <div class="myTicket">
    <NavBar :title="$t('MyTicket.title')" routeUrl="/" :logout="true" />
    <div class="myTicket-body">
      <van-tabs v-model="active" @click="getData">
        <van-tab v-for="(item, index) in list" :key="index" :title="item.name">
          <div style="margin-top: 10px"></div>
          <div v-for="(item, index) in tickerList" :key="item.id">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <div class="clearfix-title">
                  <van-tag type="primary" class="clearfix-tag" size="large">
                    {{ typeName[item.type - 1] }}
                  </van-tag>
                  <span class="ticket-date">{{ item.created_at }}</span>
                  <!-- <span class="clearfix-text">{{ item.description }}</span> -->
                </div>
              </div>
              <div class="card-body">
                <div
                  class="card-body-height card-userInfo"
                  v-if="item.game_name"
                >
                  <span>{{ item.game_name }}</span>
                  <span>{{ item.server_name }}</span>
                  <span>{{ item.role_name }}</span>
                </div>
                <div class="card-body-height card-userInfo">
                  <span v-if="item.mobile_device">{{
                    item.mobile_device
                  }}</span>
                  <span v-if="item.mobile_system_version">{{
                    item.mobile_system_version
                  }}</span>
                  <span v-if="item.device_info">{{ item.device_info }}</span>
                  <span v-if="item.network">{{ item.network }}</span>
                </div>
                <div class="card-problem">
                  <img src="@/assets/img/problem.png" alt="" srcset="" />
                  <span class="card-problem-text">
                    {{ item.description }}
                  </span>
                </div>
                <div class="cary-type">
                  <div v-if="item.type == 2">
                    <div>
                      {{ $t("problem.rechargeMethod") }}
                      ：{{ recharge_mode[item.recharge_mode - 1] }}
                    </div>
                    <div>
                      {{ $t("MyTicket.rechargeType") }}
                      ：{{ recharge_type[item.recharge_type - 1] }}
                    </div>
                    <div v-if="item.bug_game_version">
                      {{ $t("problem.gameVersion") }}
                      ：{{ item.bug_game_version }}
                    </div>
                    <div v-if="item.cardSerial">
                      {{ $t("problem.cardNum") }}：{{ item.cardSerial }}
                    </div>
                    <div v-if="item.recharge_type == 2">
                      {{ $t("MyTicket.paySuccessTime") }}：{{ item.pay_time }}
                    </div>
                    <div v-if="item.recharge_type == 2">
                      {{ $t("MyTicket.payAmount") }}：{{ item.pay_amt }}
                    </div>
                  </div>
                  <div v-if="item.type == 3">
                    <div>{{ $t("MyTicket.FBLink") }}：{{ item.fb_link }}</div>
                  </div>
                  <div v-if="item.type == 4">
                    <div>
                      {{ $t("MyTicket.occurrenceTime") }}：{{ item.occur_time }}
                    </div>
                    <div v-if="item.bug_game_version">
                      {{ $t("problem.gameVersion") }}
                      ：{{ item.bug_game_version }}
                    </div>
                  </div>
                  <div v-if="item.type == 5">
                    <div>
                      {{ $t("MyTicket.issueType") }}：
                      {{ appeal_type[item.appeal_type - 1] }}
                    </div>
                  </div>
                  <div v-if="item.type == 6">
                    <div>
                      {{ $t("problem.LoginType") }}
                      :{{ login_type[item.login_type - 1] }}
                    </div>
                    <div v-if="item.login_type == 1">
                      {{ $t("problem.loginMethod") }}
                      :{{ login_mode[item.login_mode - 1] }}
                    </div>
                    <div v-if="item.login_type == 1 && item.login_mode == 1">
                      Email:{{ item.email }}
                    </div>
                    <div>
                      {{ $t("MyTicket.occurrenceTime") }}：{{ item.occur_time }}
                    </div>
                    <div>
                      {{ $t("problem.gameVersion") }}
                      :{{ item.bug_game_version }}
                    </div>
                    <!-- <div>
                      {{ $t("problem.phoneSystemVersion") }}
                      :{{ item.mobile_system_version }}
                    </div>
                    <div>
                      {{ $t("problem.mobileDevice") }}:{{ item.mobile_device }}
                    </div>
                    <div>
                      {{ $t("problem.deviceInfo") }}:{{ item.device_info }}
                    </div>
                    <div>{{ $t("problem.network") }}:{{ item.network }}</div> -->
                  </div>
                  <div v-if="item.type == 7">
                    <div>
                      {{ $t("MyTicket.complaintType") }}：
                      {{ report_type[item.report_type - 1] }}
                    </div>
                    <div>
                      {{ $t("MyTicket.characterName") }}：
                      {{ item.report_role_name }}
                    </div>
                    <div>
                      {{ $t("MyTicket.serverName") }}：
                      {{ item.report_server_name }}
                    </div>
                    <div>
                      {{ $t("MyTicket.characterID") }}：
                      {{ item.report_role_id }}
                    </div>
                  </div>
                  <div class="card-img" v-if="item.imgUrl">
                    <div>{{ $t("MyTicket.ImageDetails") }}：</div>
                    <el-image
                      v-for="(i, ind) in item.imgUrl"
                      :key="ind"
                      :src="i"
                      :preview-src-list="[i]"
                      style="width: 50px; height: 50px; margin-right: 10px"
                    >
                    </el-image>
                  </div>
                  <div class="card-img" v-if="item.videoUrl">
                    <div>{{ $t("MyTicket.VideoDetails") }}：</div>
                    <video width="100" height="100" controls>
                      <source :src="item.videoUrl" type="video/mp4" />
                      {{ $t("MyTicket.noUseVideo") }}
                    </video>
                  </div>
                </div>
                <div class="card-reply">
                  <el-collapse v-model="activeNames[index]">
                    <el-collapse-item
                      :name="index + 1"
                      :title="status[item.status]"
                    >
                      <!-- <div v-for="i in item.replyList" :key="i.id" class="reply">
                    客服回复：{{ i.reply }}
                  </div> -->
                      <div v-if="item.replyList && item.replyList.length > 0">
                        <div
                          class="reply"
                          v-for="i in item.replyList"
                          :key="i.id"
                        >
                          <div class="reply-title">
                            <div class="reply-title-left">
                              {{ $t("MyTicket.customerServiceResponse") }}
                            </div>
                            <div class="reply-title-right">
                              {{ i.created_at }}
                            </div>
                          </div>
                          <div class="reply-body">
                            <div>{{ i.reply }}</div>
                            <div class="card-img" v-if="i.imgUrl">
                              <div>{{ $t("MyTicket.ImageDetails") }}：</div>
                              <el-image
                                v-for="(it, ind) in i.imgUrl"
                                :key="ind"
                                :src="it"
                                :preview-src-list="[it]"
                                style="
                                  width: 50px;
                                  height: 50px;
                                  margin-right: 10px;
                                "
                              >
                              </el-image>
                            </div>
                            <div class="card-img" v-if="i.videoUrl">
                              <div>{{ $t("MyTicket.VideoDetails") }}：</div>
                              <video width="100" height="100" controls>
                                <source :src="i.videoUrl" type="video/mp4" />
                                {{ $t("MyTicket.noUseVideo") }}
                              </video>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div>{{ $t("MyTicket.noReply") }}</div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
            </el-card>
          </div>
        </van-tab>
      </van-tabs>
      <Kefu />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Kefu from "@/components/kefu";
export default {
  name: "detail",
  mixins: [],
  components: { NavBar, Kefu },
  props: {},
  data() {
    return {
      active: 0,
      tickerList: [],
      activeNames: [],
    };
  },
  computed: {
    status() {
      return [
        this.$t("MyTicket.processing"),
        this.$t("MyTicket.processed"),
        this.$t("MyTicket.closed"),
      ];
    },
    login_type() {
      return [
        this.$t("problem.AccountProblem"),
        this.$t("problem.selectServerProblem"),
        this.$t("problem.roleLoginProblem"),
      ];
    },
    login_mode() {
      return [
        this.$t("problem.emailLogin"),
        this.$t("problem.FbLogin"),
        this.$t("problem.googleLogin"),
        this.$t("problem.guestLogin"),
      ];
    },
    list() {
      return [
        {
          name: this.$t("MyTicket.all"),
          type: 0,
        },
        {
          name: this.$t("Main.problem6"),
          type: 6,
        },
        // {
        //   name: this.$t("Main.problem1"),
        //   type: 1,
        // },
        {
          name: this.$t("Main.problem2"),
          type: 2,
        },
        // {
        //   name: this.$t("Main.problem3"),
        //   type: 3,
        // },
        {
          name: this.$t("Main.problem4"),
          type: 4,
        },
        {
          name: this.$t("Main.problem5"),
          type: 5,
        },
        {
          name: this.$t("Main.problem7"),
          type: 7,
        },
        {
          name: this.$t("Main.problem8"),
          type: 8,
        },
      ];
    },
    typeName() {
      return [
        this.$t("Main.problem1"),
        this.$t("Main.problem2"),
        this.$t("Main.problem3"),
        this.$t("Main.problem4"),
        this.$t("Main.problem5"),
        this.$t("Main.problem6"),
        this.$t("Main.problem7"),
        this.$t("Main.problem8"),
      ];
    },
    recharge_mode() {
      return [
        this.$t("problem.applePurchase"),
        this.$t("problem.googlePurchase"),
        this.$t("problem.WebPurchase"),
      ];
    },
    recharge_type() {
      return [
        this.$t("MyTicket.abnormalRecharge"),
        this.$t("MyTicket.itemsNotReceived"),
        this.$t("problem.cardRecharge"),
      ];
    },
    appeal_type() {
      return [
        this.$t("MyTicket.BlockIssue"),
        this.$t("MyTicket.MuteIssue"),
        this.$t("MyTicket.OtherIssues"),
      ];
    },
    report_type() {
      return [
        this.$t("MyTicket.IllegalSpeech"),
        this.$t("MyTicket.IllegalCharacterName"),
        this.$t("MyTicket.OtherViolations"),
      ];
    },
  },
  watch: {},
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    getData(e) {
      let type = this.list[e]?.type || 0;
      let params = {
        token: localStorage.getItem("token"),
        type,
      };
      // /api/newTicketList
      this.$http.post("/api/v2/ticketList", params).then((res) => {
        if (res.code === 200) {
          this.tickerList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(e) {
      this.$router.push({
        path: "/ticketInfo",
        query: {
          d: e,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-collapse-item__content {
  padding-bottom: 0;
}
/deep/.el-collapse {
  border: none;
}
/deep/ .el-collapse-item__header,
/deep/ .el-collapse-item__wrap {
  border-bottom: none;
}
.myTicket {
  height: 100%;
}
.myTicket-body {
  height: 100%;
  // background-color: #fff;
  padding: 65px 15px 20px;
  text-align: left;
  box-sizing: border-box;
}

.clearfix-title {
  display: block;
  margin-left: 5px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}

.clearfix-tag {
  margin-right: 5px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.ticket-date {
  float: right;
  padding: 3px 0;
  font-size: 12px;
  color: gray;
}
.box-card {
  width: 100%;
  margin-bottom: 30px;
}
.card-problem img {
  width: 20px;
  // display: flex;
  // align-items: center;
}
.card-problem-text {
  display: inline;
  vertical-align: top;
  font-size: 18px;
}
.card-body-height {
  margin-bottom: 12px;
}
.card-userInfo {
  font-size: 12px;
  color: gray;
}
.card-userInfo span {
  margin-right: 10px;
}
.reply {
  background-color: #f4f9fd;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.reply-title {
  display: flex;
  justify-content: space-between;
  color: gray;
  margin-bottom: 10px;
}

.cary-type {
  font-size: 14px;
}
.cary-type div {
  margin: 5px 0;
}

@media screen and (max-width: 1024px) {
  .clearfix-title {
    display: -webkit-box; /* 创建一个伸缩盒子模型 */
    -webkit-box-orient: vertical; /* 纵向排列 */
    -webkit-line-clamp: 2; /* 限制行数为两行 */
    white-space: normal; /* 允许换行 */
  }
}
</style>
