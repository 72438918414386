<template>
  <div class="setPassword">
    <NavBar :title="title" :routeUrl="type === '1' ? '/':'/login'" />
    <div class="setPassword-body">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item prop="email">
          <el-input
            name="username"
            :readOnly="readOnly"
            oninput="value=value.replace(/[^\x00-\xff]/g, '')"
            v-model.trim="ruleForm.email"
            :placeholder="$t('Login.emailPlaceholder')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-col :span="16">
            <el-form-item prop="code">
              <el-input
                type="text"
                v-model.trim="ruleForm.code"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                :placeholder="$t('Login.codePlaceholder')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
          <el-col :span="7">
            <el-button style="width: 100%" type="primary" @click="getCode()">
              {{ codeTime > 0 ? codeTime : $t("Login.getCode") }}
            </el-button>
          </el-col>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            show-password
            v-model.trim="ruleForm.password"
            :placeholder="$t('Login.passwordPlaceholder')"
            oninput="value=value.replace(/[^\x00-\xff]/g, '')"
            readonly
            onfocus="this.removeAttribute('readonly');"
            autocomplete="new-password"
            type="password"
            name="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
            show-password
            v-model.trim="ruleForm.newPassword"
            :placeholder="$t('Login.repeatPassword')"
            oninput="value=value.replace(/[^\x00-\xff]/g, '')"
            readonly
            onfocus="this.removeAttribute('readonly');"
            autocomplete="new-password"
            type="password"
            name="password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="width: 100%"
            @click="submitForm('ruleForm')"
            >{{ $t("Login.changePassword") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
export default {
  data() {
    return {
      codeTime: 0,
      token: localStorage.getItem("token"),
      ruleForm: {
        email: "",
        code: "",
        password: "",
        newPassword: "",
      },
      readOnly:false,
      // title: "",
      type: "1",
      rules: {
        email: [
          {
            required: true,
            message: this.$t("Login.emailPlaceholder"),
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("Login.codePlaceholder"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("Login.newPassword"),
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: this.$t("Login.repeatPassword"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    let type = this.$route.query.type;
    this.type = type;
    let email = localStorage.getItem("username");
    if (this.type === "1") {
      this.ruleForm.email = email
      this.readOnly = true
    }
  },
  computed: {
    title() {
      return this.type === "1"
        ? this.$t("Login.changePassword")
        : this.$t("Login.forgetPassword");
    },
  },
  components: { NavBar },
  methods: {
    getCode() {
      if (this.ruleForm.email === "") {
        this.$message.warning(this.$t("Login.emailCheck"));
        return;
      }
      if (this.codeTime <= 59 && this.codeTime > 0) {
        return;
      }
      let params = {
        email: this.ruleForm.email,
      };
      this.$http.post("/api/v2/sendEmailCode", params).then((res) => {
        if (res.code === 200) {
          this.codeTime = 59;
          let codeTimeInt = setInterval(() => {
            this.codeTime = this.codeTime - 1;
            if (this.codeTime == 0) {
              clearInterval(codeTimeInt);
            }
          }, 1000);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.password === this.ruleForm.newPassword) {
            let params = {
              email: this.ruleForm.email, //邮箱账号
              new_passwd: this.ruleForm.password, //密码
              auth_code: this.ruleForm.code, //短信验证码
            };
            this.$http.post("/api/v2/modifyPassword", params).then((res) => {
              if (res.code === 200) {
                this.$router.push("/login");
                this.$message.success(this.$t("Login.PasswordSuccess"));
              } else {
                this.$message.warning(res.msg);
              }
            });
          } else {
            this.$message.warning(this.$t("Login.differencePassword"));
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped lang='scss'>
.setPassword-body {
  margin-top: 45px;
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  text-align: left;
  color: gray;
  font-size: 14px;
}
</style>