import axios from "axios";
import route from '../router/index';

const instance = axios.create({
  // baseURL: "https://test-upac.muugame.com",
  baseURL: "/apis/",
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理
    if (config.method == "post") {
      config.headers['Content-Type'] = config.headers['Content-Type'] + ";multipart/form-data"
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做一些处理
    if (response.data.code === 206) {
      route.push("/login");
    }
    return response.data;
  },
  (error) => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

export default instance;
