<template>
  <div class="login">
    <div class="login-form">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item style="text-align: center">
          <el-radio-group v-model="ruleForm.type" @change="handleTypeChange">
            <el-radio label="1" value="1">Email</el-radio>
            <el-radio label="2" value="2">Phone</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="account">
          <div class="form-bg">
            <el-input
              v-model="ruleForm.account"
              :placeholder="ruleForm.type === '1' ? 'Email' : 'phone'"
            >
              <template slot="prepend" v-if="ruleForm.type == '2'"
                >84 -
              </template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <div class="form-bg">
            <el-input
              v-model="ruleForm.password"
              :placeholder="$t('Login.passwordPlaceholder')"
              show-password
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">
            {{ $t("Login.login") }}
          </el-button>
        </el-form-item>
        <div class="set-password" @click="handleRouter('setPassword')">
          {{ $t("Login.forgetPassword") }}
        </div>
      </el-form>
    </div>
    <div class="login-other">
      <div class="login-text">{{ $t("Login.other") }}</div>
      <ul class="list-action">
        <li>
          <img
            style="cursor: pointer; width: 42px"
            @click="fbLogin"
            src="@/assets/img/facebook.png"
            alt=""
          />
        </li>
        <li v-show="client_id">
          <GoogleLogin :client_id="client_id" ref="GoogleLogin" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import FbLogin from "../../components/FbLogin.vue";
import GoogleLogin from "../../components/GoogleLogin.vue";
export default {
  name: "login",
  mixins: [],
  components: { FbLogin, GoogleLogin },
  props: {},
  data() {
    return {
      fb_appid: "1114786559496051", //fb登录id
      client_id:
        "698305642286-d168sf42qjnc1rv5piu9c6nhm9tkdvag.apps.googleusercontent.com", //谷歌登录id
      fbIsOK: false,
      ruleForm: {
        account: "",
        password: "",
        type: "1",
      },
      rules: {
        account: [{ required: true, message: this.$t("Login.accountPlaceholder"), trigger: "blur" }],
        password: [{ required: true, message: this.$t("Login.passwordPlaceholder"), trigger: "blur" }],
      },
      checked: true,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.autoLogin();
    this.getFb();
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.ruleForm = userInfo;
    }
  },
  methods: {
    getFb() {
      // fbid
      const fb_appid = "1114786559496051";
      // 加载 facebook SDK
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      window.fbAsyncInit = function () {
        this.fbIsOK = true;
        FB.init({
          appId: fb_appid,
          cookie: true,
          xfbml: true,
          version: "v2.8",
        });
      };
    },
    fbLogin(resCallback) {
      let _this = this;
      FB.login(
        function (response) {
          if (response.status === "connected") {
            FB.api(
              "/me?fields=id,name,token_for_business",
              function (response) {
                let params = {
                  username: response.token_for_business,
                  login_type: 3,
                };
                _this.$http.post("/api/login", params).then((data) => {
                  _this.logining = false;
                  if (data.code !== 200) {
                    _this.$message({
                      message: data.msg,
                      type: "error",
                    });
                  } else {
                    localStorage.setItem("token", data.data.token);
                    localStorage.setItem("username", data.data.username);
                    _this.$router.push({ path: "/" });
                  }
                });
              }
            );
          } else {
          }
        },
        { scope: "public_profile,email" }
      );
    },
    handleTypeChange(e) {
      let type = JSON.parse(localStorage.getItem("userInfo"));
      if (e === type.type) {
        this.ruleForm = type;
      } else {
        this.ruleForm = { account: "", password: "", type: e };
      }
    },
    submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let loginParams = {
            username: this.ruleForm.account,
            login_type: this.ruleForm.type,
            password: this.ruleForm.password,
          };
          this.$http.post("/api/login", loginParams).then((data) => {
            _this.logining = false;
            if (data.code !== 200) {
              _this.$message({
                message: data.msg,
                type: "error",
              });
            } else {
              localStorage.setItem("token", data.data.token);
              let userInfo = this.ruleForm;
              if (this.checked) {
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
              } else {
                localStorage.removeItem("userInfo");
              }
              localStorage.setItem("username", data.data.username);
              this.$router.push({ path: "/" });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    autoLogin() {
      // if (window.location.search.includes("fbclid")) {
      //   window.location.href = window.location.origin + window.location.pathname + window.location.hash
      // }
      let routeToken = this.$route.query.token;
      let username = this.$route.query.username;
      if (routeToken) {
        localStorage.setItem("token", routeToken);
      }
      if (username) {
        localStorage.setItem("username", username);
      }
      if (routeToken && username) {
        this.$router.push({ path: "/" });
      }
    },
    handleRouter(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-input {
  width: 99%;
}
/deep/ .el-input__inner {
  border: none;
  background-color: transparent;
}
/deep/ .el-form-item__error {
  left: 20px;
}
/deep/.el-input-group__prepend {
  border: none;
  padding: 0 10px;
}
.el-button {
  margin-top: 20px;
  width: 95%;
  border-radius: 10px;
}
.login {
  background-color: #fff;
  height: 100vh;
  overflow: auto;
}
.login-form {
  margin-top: 50px;
}
.form-bg {
  background-color: #f5f5f5;
  border-radius: 10px; /* 设置圆角，20px 为圆角半径 */
  padding: 5px 0;
}
.login-other {
  margin-top: 50px;
}
.login-text {
  color: #aab1bc;
  font-size: 12px;
  margin-bottom: 20px;
}
.list-action {
  padding: 0;
  margin: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  li {
    list-style-type: none;
    margin: 0 10px;

    a {
      font-size: 12px;
      color: #000;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      display: block;
      text-decoration: none !important;

      img {
        width: 24px;
        height: 24px;
        border: 0;
        display: block;
        margin: auto;
        margin-bottom: 7px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }
    }
  }
}
.set-password {
  text-align: right;
  color: gray;
  cursor: pointer;
  color: #409eff;
}
@media screen and (max-width: 1024px) {
  .el-button {
    width: 80%;
  }
  .login-form {
    padding: 0 30px;
  }
}
</style>
